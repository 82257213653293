import React, {useEffect, useState} from 'react';

// Firebase
import getFirebase from '../utils/firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/app';
import "firebase/auth";

// Gatsby
import {navigate} from "gatsby";
import Layout from "../components/layout";

// Material UI
// import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {blue} from '@material-ui/core/colors';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// Material Icons
import ExploreIcon from '@material-ui/icons/Explore';
import SendIcon from '@material-ui/icons/Send';
import FavoriteIcon from '@material-ui/icons/Favorite';

// Misc
import 'typeface-roboto';
import SEO from "../components/seo";

// Components
// import HomeDrinkCard from '../components/HomeDrinkCard';

// Data
// import {DrinkData} from '../data/DrinkData.js';

import shot from "../images/shot.png"

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID]
};

// Animations
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props}/>;
});

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'Work Sans',
    flexGrow: 1,
    backgroundColor: '',
    minHeight: '100vh',
    marginBottom: '-1px'
  },
  logo: {
    flexGrow: 1,
    backgroundColor: '',
    fontFamily: 'Work Sans',
    fontWeight: '700',
    fontSize: '23px',
    "&:hover": {
      color: '#2D3A95',
      cursor: 'pointer'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '19px'
    }
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600]
  },
  titleText: {
    backgroundColor: '',
    fontWeight: '800',
    fontSize: '70px',
    textAlign: 'center',
    fontFamily: 'Work Sans',
    lineHeight: '1',
    padding: '12px',
    [
      theme
        .breakpoints
        .down('md')
    ]: {
      fontSize: '60px',
      marginBottom: '-10px'
    },
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '60px',
      marginBottom: '-10px',
      lineHeight: '0.95'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '12vw',
      marginBottom: '-10px',
      paddingBottom: '10px'
    }
  },
  subtitleText: {
    fontFamily: 'Work Sans',
    fontSize: '30px',
    fontWeight: '600',
    textAlign: 'center',
    margin: '2px 0px 25px',
    letterSpacing: '-1px',
    lineHeight: '1',
    // color: '#545454',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '30px',
      margin: '10px 0px 15px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '6.5vw'
    }
  },
  navBar: {
    backgroundColor: '',
    width: '100vw',
    position: 'absolute',
    padding: '18px 28px'
  },
  title: {
    backgroundColor: '',
    width: '95vw',
    padding: '15vh 5vw 8vh',
    [
      theme
        .breakpoints
        .down('md')
    ]: {
      padding: '12vh 5vw 5vh'
    },
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      // padding: '12vh 5vw 5vh',
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      padding: '12vh 5vw 0vh',
      marginBottom: '-15px'
    }
  },
  chip: {
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      marginTop: '-20px'
    }
  },
  drinkCardContainer: {
    backgroundColor: '',
    paddingRight: '10vw',
    [
      theme
        .breakpoints
        .down('md')
    ]: {
      paddingRight: '5vw'
    },
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      paddingRight: '0vw'
    }
  },
  infoContainer: {
    width: '85%',
    backgroundColor: '',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingTop: '50px',
    paddingBottom: '30px',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      paddingTop: '40px'
    }
  },
  featuresContainer: {
    backgroundColor: '',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 20px',
    paddingTop: '40px',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      paddingTop: '20px'
    }
  },
  icons: {
    color: '#2b3659'
  },
  featureText: {
    fontSize: '28px',
    fontWeight: '500',
    backgroundColor: '',
    textAlign: 'center',
    padding: '10px',
    // paddingLeft: '20px',
    margin: '0px',
    // color: '#545454',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '28px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '4.8vw'
    },
    "&:hover": {
      color: '#121212',
      cursor: 'pointer'
    }
  },
  featureDescriptionText: {
    fontSize: '20px',
    fontWeight: '300',
    backgroundColor: '',
    textAlign: 'center',
    // paddingLeft: '20px',
    margin: '0px',
    // color: '#545454',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '28px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '4.8vw'
    },
    "&:hover": {
      color: '#121212',
      cursor: 'pointer'
    }
  },
  ctaContainer: {
    margin: '20px',
    backgroundColor: '',
    padding: '50px 0px 60px',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      paddingTop: '40px',
      paddingBottom: '50px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      paddingBottom: '20px'
    }
  },
  credits: {
    backgroundColor: '',
    color: '#7a7a7a',
    fontSize: '20px',
    fontWeight: '300',
    textAlign: 'center',
    lineHeight: '1.2',
    [
      theme
        .breakpoints
        .down('md')
    ]: {
      fontSize: '20px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      paddingBottom: '20px',
      width: '250px'
    }
  },
  linkText: {
    color: '#2b2c36',
    textDecoration: 'none'
  },
  smallText: {
    color: '#7a7a7a',
    fontSize: '16px',
    fontWeight: '300',
    textAlign: 'center',
    cursor: 'pointer',
    onhover: {
      color: '#2b2c36'
    }
  },
  ctaText: {
    backgroundColor: '',
    color: '',
    fontFamily: 'Work Sans',
    fontSize: '48px',
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: '1.1',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '60px',
      fontWeight: '700'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '10vw',
      fontWeight: 'bold',
      paddingBottom: ''
    }
  },
  mainBody: {
    backgroundColor: '',
    paddingBottom: '10px',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      paddingBottom: '40px'
    }
  },
  mainTitle: {
    paddingBottom: '4vh',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      paddingBottom: '8vh'
    }
  },
  button: {
    margin: '8px',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      margin: '5px'
    }
  },
  dialog: {
    padding: '40px',
    textAlign: 'center',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      padding: '20px'
    }
  },
  screenshotContainer: {
    // width: '80%',
    [
      theme
        .breakpoints
        .up('sm')
    ]: {
      width: '80%'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      width: '95%',
      paddingTop: '20px'
    }
  },
  ctaTitleText: {
    backgroundColor: '',
    fontWeight: '800',
    fontSize: '60px',
    textAlign: 'center',
    fontFamily: 'Work Sans',
    lineHeight: '1',
    padding: '12px',
    [
      theme
        .breakpoints
        .down('md')
    ]: {
      fontSize: '60px'
    },
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '60px',
      marginBottom: '-5px',
      lineHeight: '0.95'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '12vw',
      paddingBottom: '10px'
    }
  },
  ctaSubtitleText: {
    fontFamily: 'Work Sans',
    fontSize: '30px',
    fontWeight: '600',
    textAlign: 'center',
    margin: '2px 0px 10px',
    letterSpacing: '-1px',
    lineHeight: '1',
    // color: '#545454',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '30px',
      margin: '10px 0px 15px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '6.5vw'
    }
  }
}));

// Login Dialog
function LoginDialog(props) {
  const [styledAuth, setStyledAuth] = useState(false)

  useEffect(() => {
    setStyledAuth(
      firebase
      ? <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
      : undefined)
  }, []);

  const classes = useStyles();
  const {onClose, open} = props;

  const handleClose = () => {
    onClose();
  };

  return (<Dialog onClose={handleClose} aria-labelledby="login-dialog-title" open={open} TransitionComponent={Transition}>
    <div className={classes.dialog}>
      <DialogTitle>Continue to DrinkMix</DialogTitle>
      {styledAuth}
    </div>
  </Dialog>);
}

function Landing() {
  const classes = useStyles();

  const [isLoaded, setIsLoaded] = useState(false)

  // Drink Card State
  // const activeDrink = DrinkData[1];
  // const [cardRaised, setCardRaised] = useState(false)

  // User State
  const [activeUser, setActiveUser] = useState(undefined);

  // Dialog State
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  // Animation State
  const [titleDisplayed, setTitleDisplayed] = useState(false)
  const [detailsDisplayed, setDetailsDisplayed] = useState(false)
  // const [CardDisplayed, setCardDisplayed] = useState(false)

  // Functions
  const openSigninDialog = () => {
    setDialogIsOpen(true);
  };
  const closeDialog = value => {
    setDialogIsOpen(false);
  };
  // const openRandomDrink = () => {
  //   const randomDrinkIndex = Math.floor(Math.random() * DrinkData.length);
  //   navigate(DrinkData[randomDrinkIndex].link)
  // };

  // Animation Functions
  const displayTitle = () => {
    setTitleDisplayed(true)
  }
  const displayDetails = () => {
    setDetailsDisplayed(true)
  }
  // const displayCard = () => {
  //   setCardDisplayed(true)
  // }
  // const raiseCard = () => {
  //   setCardRaised(true)
  // };
  // const lowerCard = () => {
  //   setCardRaised(false)
  // };

  // Effect Hooks
  // useEffect(() => {
  //   firebase
  //     .auth()
  //     .onAuthStateChanged((user) => {
  //       if (user) {
  //          alert('User is signed in')
  //          setIsSignedIn(!!user);
  //         setActiveUser(user);
  //         if (activeUser) {
  //           navigate('/signedin')
  //         }
  //       } else {
  //          setIsSignedIn(undefined)
  //         setActiveUser(undefined);
  //       }
  //     });
  // })
  const firebase = getFirebase();

  useEffect(() => {
    if (!firebase)
      return;
    if (isLoaded) 
      return;
    firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          // alert('User is signed in')
          // setIsSignedIn(!!user);
          setActiveUser(user);
          if (activeUser) {
            navigate('/home')
          }
        } else {
          // setIsSignedIn(undefined)
          setIsLoaded(true)
        }
      });
  });

  useEffect(() => {
    setTimeout(displayTitle, 600);
    // setTimeout(displayCard, 400);
    setTimeout(displayDetails, 300);
  });

  // <Grid item="item">
  //   {credits}
  // </Grid>

  // Components

  // const credits = (<Typography className={classes.credits} variant='h6'>Made by
  //   <a className={classes.linkText} href="https://solomongomez.com">{' Solomon Gomez '}</a>
  //   with
  //   <a className={classes.linkText} target="_blank" rel="noopener noreferrer" href="https://reactjs.org/">{' React '}</a>
  //   and
  //   <a className={classes.linkText} target="_blank" rel="noopener noreferrer" href="https://firebase.google.com/">{' Firebase'}</a>
  // </Typography>);

  // <Grid item="item">
  //   <Chip label="Join the Beta" variant="outlined" color="primary" onClick={openSigninDialog} className={classes.chip}/>
  // </Grid>

  const navBar = (<Fade in='in'>
    <Grid container="container" justify="space-between" alignItems="center" className={classes.navBar}>
      <Grid item="item">
        <Typography variant="h6" onClick={openSigninDialog} className={classes.logo}>
          DrinkMix
        </Typography>
      </Grid>
      <Grid item="item">
        <Button color="primary" onClick={openSigninDialog} size='large'>Sign In</Button>
      </Grid>
    </Grid>
  </Fade>)
  const title = (<Fade in={titleDisplayed}>
    <Grid container="container" direction='column' justify='space-evenly' alignItems='center' spacing={2} className={classes.title}>
      <Typography className={classes.titleText}>Say Hello to DrinkMix</Typography>
      <Typography className={classes.subtitleText}>The new way to discover, collect, and share great cocktail recipes.</Typography>
      <Button size='large' color="secondary" variant='contained' onClick={openSigninDialog} className={classes.button}>Get Early Access</Button>
    </Grid>
  </Fade>)
  const screenshot = (<Grid className={classes.screenshotContainer}><img src={shot} alt="Screenshot"/></Grid>)
  // const card = (<Slide in={CardDisplayed} direction='right'>
  //   <Grid item="item" xs={12} md={6} className={classes.drinkCardContainer}>
  //     <Grid container="container" direction="column" justify="center" alignItems="center" className={classes.drinkCard} onMouseOver={raiseCard} onMouseOut={lowerCard} onFocus={raiseCard} onBlur={lowerCard}>
  //       <HomeDrinkCard drinkData={activeDrink} raised={cardRaised} onClick={openSigninDialog}/>
  //     </Grid>
  //   </Grid>
  // </Slide>)
  // const details = (<Slide in={detailsDisplayed} direction='left'>
  //   <Grid xs={12} md={6} className={classes.infoContainer}>
  //     <Grid container="container" direction="column" justify="center" alignItems="center">
  //       <Grid className={classes.featuresContainer}>
  //         <ExploreIcon fontSize="large" style={{
  //             color: '#2b3659'
  //           }}/>
  //         <Typography className={classes.featureText} onClick={openSigninDialog}>Discover Drink Recipes</Typography>
  //       </Grid>
  //       <Grid className={classes.featuresContainer}>
  //         <FavoriteIcon fontSize="large" style={{
  //             color: '#e85462'
  //           }}/>
  //         <Typography onClick={openSigninDialog} className={classes.featureText}>Save Your Favourites</Typography>
  //       </Grid>
  //       <Grid className={classes.featuresContainer}>
  //         <SendIcon fontSize="large" style={{
  //             color: '#63b6ba'
  //           }}/>
  //         <Typography onClick={openSigninDialog} className={classes.featureText}>Share with Friends</Typography>
  //       </Grid>
  //       <Grid>
  //         <div style={{
  //             display: 'flex',
  //             paddingTop: '5px'
  //           }}>
  //           <Grid container="container" direction="column" justify="center" alignItems="center" spacing={2} className={classes.ctaContainer}>
  //             <Grid item="item" xs="xs">
  //               <Typography className={classes.ctaText}>Get Early Access</Typography>
  //             </Grid>
  //             <Grid item="item" xs="xs">
  //               <Grid container="container" justify="space-evenly" alignItems="center">
  //                 <Button size='large' color="secondary" variant='contained' onClick={openSigninDialog} className={classes.button}>Get Early Access</Button>
  //                 <Button size='large' color="secondary" variant='outlined' onClick={openSigninDialog} className={classes.button}>Learn More</Button>
  //               </Grid>
  //             </Grid>
  //           </Grid>
  //         </div>
  //       </Grid>
  //     </Grid>
  //   </Grid>
  // </Slide>)
  const newDetails = (<Fade in={detailsDisplayed}>
    <Grid className={classes.infoContainer}>
      <Grid container="container" justify="center" alignItems="center" spacing={12}>
        <Grid direction="column" xs={12} md={4} className={classes.featuresContainer}>
          <ExploreIcon fontSize="large" style={{
              // color: '#2b3659'
            }}/>
          <Typography className={classes.featureText} onClick={openSigninDialog}>Discover Drink Recipes</Typography>
          <Typography onClick={openSigninDialog} className={classes.featureDescriptionText}>Find your next favourite drink with personalized recommendations and a growing catalog of awesome recipes.</Typography>
        </Grid>
        <Grid direction="column" xs={12} md={4} className={classes.featuresContainer}>
          <FavoriteIcon fontSize="large" style={{
              // color: '#e85462'
            }}/>
          <Typography onClick={openSigninDialog} className={classes.featureText}>Save Your Favourites</Typography>
          <Typography onClick={openSigninDialog} className={classes.featureDescriptionText}>Add the recipes you love to your favourites and easily access them across desktop, mobile, and tablet.</Typography>

        </Grid>
        <Grid direction="column" xs={12} md={4} className={classes.featuresContainer}>
          <SendIcon fontSize="large" style={{
              // color: '#63b6ba'
            }}/>
          <Typography onClick={openSigninDialog} className={classes.featureText}>Share with Friends</Typography>
          <Typography onClick={openSigninDialog} className={classes.featureDescriptionText}>Create your own recipes to share with the community or send recipes to your friends with the tap of a button.</Typography>
        </Grid>
        <Grid>
          <div style={{
              display: 'flex'
            }}>
            <Grid container="container" direction="column" justify="center" alignItems="center" spacing={2} className={classes.ctaContainer}>
              <Grid item="item" xs="xs">
                <Typography className={classes.ctaTitleText}>We're Launching Soon</Typography>
                <Typography className={classes.ctaSubtitleText}>Join the pre-launch waitlist and get early access to DrinkMix.</Typography>
              </Grid>
              <Grid item="item" xs="xs">
                <Grid container="container" justify="space-evenly" alignItems="center">
                  <Button size='large' color="secondary" variant='contained' onClick={openSigninDialog} className={classes.button}>Get Early Access</Button>
                  <Button size='large' color="secondary" variant='outlined' onClick={openSigninDialog} className={classes.button}>Already a Member</Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Grid>
  </Fade>)

  const finalPage = isLoaded
    ? <Layout>
        <SEO title="Home"/>
        <div className={classes.root}>
          {navBar}
          <Grid container="container" direction="column" justify="center" alignItems="center" className={classes.root}>
            <Grid item="item" xs={12} className={classes.mainTitle}>
              {title}
            </Grid>
            <Grid item="item" xs={12} className={classes.mainBody}>
              <Grid container="container" justify="center" alignItems="center" spacing={0}>
                <Fade in={detailsDisplayed}>{screenshot}</Fade>
                {newDetails}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <LoginDialog open={dialogIsOpen} onClose={closeDialog}/>
      </Layout>
    : <Grid container="container" direction="row" justify="center" alignItems="center" style={{
        height: '100vh',
        width: '100vw'
      }}><CircularProgress/></Grid>

  // Render
  return (finalPage);
}

export default Landing
